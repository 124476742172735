import React, { useCallback, useState } from 'react'

import { AButton } from '@atoms/AButton'

export const OProofOfConcept = ({ onAssistantClick, onCheckInClick }) => {
  const [isAssistantLoading, setIsAssistantLoading] = useState(false)
  const [activeAssistant, setActiveAssistant] = useState('')

  const handleAssistantClick = useCallback(
    async ({ type, input, activeAssistant }) => {
      setIsAssistantLoading(true)
      setActiveAssistant(activeAssistant)

      try {
        await onAssistantClick({ type, input })
      } catch (err) {
        console.error(err)
      } finally {
        setIsAssistantLoading(false)
        setActiveAssistant('')
      }
    },
    [onAssistantClick]
  )

  const handleCheckInClick = useCallback(
    async ({ activeAssistant }) => {
      setIsAssistantLoading(true)
      setActiveAssistant(activeAssistant)

      try {
        await onCheckInClick()
      } catch (err) {
        console.error(err)
      } finally {
        setIsAssistantLoading(false)
        setActiveAssistant('')
      }
    },
    [onCheckInClick]
  )

  return (
    <div className="w-full flex flex-col space-y-4 p-4">
      <AButton
        isLoading={isAssistantLoading && activeAssistant === 'triage'}
        onClick={() => {
          handleAssistantClick({ type: 'triage', activeAssistant: 'triage' })
        }}
      >
        Jump to triage
      </AButton>
      <AButton
        isLoading={isAssistantLoading && activeAssistant === 'check-in'}
        onClick={() => {
          handleCheckInClick({ activeAssistant: 'check-in' })
        }}
      >
        Generate check-in
      </AButton>
    </div>
  )
}
