import React, { useMemo, useRef } from 'react'
import classNames from 'classnames'

import { useChatAnimation, useThreadEngine } from '@edwin/react-sdk'

import { OChatMessage } from './OChatMessage'

export const OThreadChat = ({ thread, isNewMessagesLoading = false }) => {
  const refWrapper = useRef()

  const messages = useThreadEngine({
    messages: thread?.messages || [],
    isNewMessagesLoading,
  })

  const messagesWithoutDuplicates = useMemo(() => {
    return messages.filter(msg => !msg?.skip)
  }, [messages])

  const scrollChatBottom = useChatAnimation(messages, refWrapper, true)

  return (
    <div ref={refWrapper} className="px-2.5 h-full overflow-y-auto">
      {messagesWithoutDuplicates.map(msg => (
        <div
          key={msg.id}
          className={classNames(
            'first:pt-6',
            msg?.preventAnimateIn ? '' : 'animate-message opacity-0'
          )}
        >
          <OChatMessage msg={msg} scrollChatBottom={() => {}} disableTyping />
        </div>
      ))}
    </div>
  )
}
