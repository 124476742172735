import React, { useCallback, useState } from 'react'
import useStore from '@store/useStore'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@const/Routes'

import { useThreadUI } from '@hooks/useThreadUI'

import { Mission } from '@edwinsandbox/react-web'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { MChatInput } from '@molecules/MChatInput'

const MISSION = {
  title: 'Title',
  messages: [
    {
      id: 'oOKUXZdtoHGki6tbqFmEj',
      start: true,
      trigger: '6vs0zdj2jvBzwa2SaDHHu',
      type: 'bot',
      msg: 'To perform this behavior consistently we need to keep you engaged. Here are some things I can do to help.',
    },
    {
      id: '6vs0zdj2jvBzwa2SaDHHu',
      type: 'user-options',
      options: [
        {
          id: 'user-options--6vs0zdj2jvBzwa2SaDHHu__user-option--sTjqiguWMMXzywUHVr_Mf',
          label: 'Send you memes each day',
          trigger: '_frDVtjRTbGvVVaDPODVu',
          engagement: 'Send me breathing memes each night before i go to bed at 9pm',
        },
        {
          id: 'user-options--wafwafaw',
          label: 'Write you a jingle to help remember the behavior',
          trigger: '_frDVtjRTbGvVVaDPODVu',
          engagement: 'Write me a jingle to help me remember to brush my teeth',
        },
        {
          id: 'user-options--6vs0zdj2jvBzwa2SaDHHu__user',
          label: 'Speak to me in Shakespearean sonnets',
          trigger: '_frDVtjRTbGvVVaDPODVu',
          engagement: 'Speak to me in Shakespearean sonnets',
        },
      ],
      end: false,
      start: false,
    },
    {
      id: '_frDVtjRTbGvVVaDPODVu',
      start: false,
      trigger: 'ZHrVWBH9Hv-QHZWvKIfZx',
      type: 'bot',
      msg: "Great! Let's start working on that.",
    },
    {
      id: 'ZHrVWBH9Hv-QHZWvKIfZx',
      type: 'user-options',
      options: [
        {
          id: 'user-options--ZHrVWBH9Hv-QHZWvKIfZx__user-option--6mdtExCwHQcZFhf3diYHS',
          label: "I'm ready!",
          trigger: 'Jbbbos-fmq9cEqHZYXFsT',
        },
      ],
      end: false,
      start: false,
    },
    { id: 'Jbbbos-fmq9cEqHZYXFsT', start: false, type: 'user-options', end: true },
  ],
}

export const PEngagement = () => {
  const navigate = useNavigate()

  const [engagement, setEngagement] = useState([])
  const [isNewMessagesLoading, setIsNewMessagesLoading] = useState(false)

  const user = useStore(state => state.user)

  const sendMessage = useCallback(async message => {
    setIsNewMessagesLoading(true)

    // @toDo: handle send message
  }, [])

  const { modifiedThread, handleUserInput } = useThreadUI({
    thread: null,
    handleNewMessage: sendMessage,
  })

  return (
    <OPageWrapper
      className="flex flex-col h-full"
      contentClassName="flex flex-col gap-y-3 divide-y-[1px] divide-gray-300"
    >
      <div className="h-1/5 pb-4">
        <div className="text-sm">
          <span className="font-bold">Motivation Plan.</span> I’d like you to keep me engaged by:
        </div>
        <div className="flex-1 flex flex-col items-center justify-center gap-y-2 px-8 h-full">
          {engagement.map((item, index) => (
            <div key={index} className="py-2 px-4 text-sm border border-codGray rounded-lg">
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="flex-1 flex flex-col h-4/5 pt-4">
        <div className="h-full pb-2 overflow-hidden">
          <Mission
            user={user}
            mission={MISSION}
            onFinish={() => {
              navigate(ROUTES.CHECK_IN)
            }}
            onUserAction={(options, option) => {
              if (option?.engagement) {
                console.log('triage: ', option.engagement)
                setEngagement(prev => [...prev, option.engagement])
              }
            }}
            shouldDisplayCover={false}
            shouldDisplaySidebar={false}
            shouldScrollItself
          />
        </div>
        <MChatInput
          handleOnSubmit={handleUserInput}
          isInputDisabled={false}
          isNewMessagesLoading={isNewMessagesLoading}
          isEnableToCancel={false}
          handleCancelCurrentThread={() => {
            //
          }}
          className="px-2.5 sm:px-0"
        />
      </div>
    </OPageWrapper>
  )
}
