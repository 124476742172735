import { useCallback, useState } from 'react'

import { useGoalProvider } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'

const ASSISTANT_TYPES = {
  triage: 'triage',
  choose_behavior: 'choose_behavior',
  check_in: 'check_in',
  end: 'end',
}

export const MAssistantChatEndFlow = ({ text, name }) => {
  const { onAssistantAction = () => {} } = useGoalProvider()

  const [isLoading, setIsLoading] = useState(false)

  const handleAssistantAction = useCallback(
    async (data = {}) => {
      setIsLoading(true)
      const type = ASSISTANT_TYPES[name]

      await onAssistantAction({ ...data, type })
      setIsLoading(false)
    },
    [name, onAssistantAction]
  )

  return (
    <div className="py-3 px-3.5">
      <h4 className="text-lg font-p22">{text}</h4>
      <AButton onClick={handleAssistantAction} outline small className="mt-3" isLoading={isLoading}>
        Thank you!
      </AButton>
    </div>
  )
}
