import { useCallback, useEffect, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { useGoalProvider } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'

export const MChatGoal = ({
  title = '',
  description = '',
  remindersTemplates = [],
  onProceed,
  templateId,
  ...rest
}) => {
  const { onGoal = () => {}, doesGoalExist } = useGoalProvider()

  const isFinishedRef = useRef(false)

  const areGoalAlreadyExist = useMemo(() => {
    return doesGoalExist(templateId)
  }, [doesGoalExist, templateId])

  const goalData = useMemo(() => {
    return {
      templateId,
      title,
      description,
      remindersTemplates,
      ...rest,
    }
  }, [description, remindersTemplates, rest, templateId, title])

  const handleOnProceed = useCallback(() => {
    onGoal(goalData)
    isFinishedRef.current = true

    onProceed()
  }, [goalData, onGoal, onProceed])

  const handleSkipGoal = useCallback(() => {
    isFinishedRef.current = true

    onProceed()
  }, [onProceed])

  useEffect(() => {
    if (isFinishedRef.current || !areGoalAlreadyExist) {
      return
    }

    isFinishedRef.current = true
    setTimeout(onProceed, 50)
  }, [areGoalAlreadyExist, onProceed])

  return (
    <div className="py-3 px-1.5">
      <span className="py-1 px-2 text-xs text-codGray font-semibold bg-green-light rounded">
        Your goal
      </span>
      <div className="px-2">
        {title && (
          <>
            <h4 className="mt-2 text-[18px] leading-[24px]">{title}</h4>
            <div className="w-12 h-1 mt-1.5 mb-4 bg-codGray" />
          </>
        )}
        {description && <p className="mb-1 text-sm">{description}</p>}
        {/*<div*/}
        {/*  className={classnames(*/}
        {/*    'mb-5',*/}
        {/*    (title || description) && remindersTemplates.length && 'mt-8 border-t border-white'*/}
        {/*  )}*/}
        {/*>*/}
        {/*  {remindersTemplates.map(({ id, title, time }) => (*/}
        {/*    <div key={id} className="py-2 text-sm tracking-tight border-b border-white">*/}
        {/*      {title} <span className="font-bold">@ {time}</span>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
        {!isFinishedRef.current && <div className="h-px mt-5 mb-3 bg-alto" />}
        {areGoalAlreadyExist && <div className="mt-px pt-2.5 pb-2">Goal already exists</div>}
        {!areGoalAlreadyExist && !isFinishedRef.current && (
          <div className="flex gap-x-1.5">
            <AButton small dontRound outline onClick={handleSkipGoal}>
              Not right now
            </AButton>
            <AButton small dontRound onClick={handleOnProceed}>
              Confirm goal
            </AButton>
          </div>
        )}
      </div>
    </div>
  )
}
