import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export const useAssistantStore = create(
  persist(
    (set, get) => ({
      data: [],

      addData: newData => {
        set({
          data: [...get().data, newData],
        })
      },
      clearData: () => {
        set({
          data: [],
        })
      },

      clearLocalStorage: () => {
        // default values
        set({
          data: [],
        })
      },
    }),
    {
      name: 'assistant-storage',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

export default useAssistantStore
