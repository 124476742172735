import React from 'react'
import { useChatComponents, useMessages, customTypingMethods } from '@edwin/react-sdk'

import { MChatMsgWrapper } from '../molecules/MChatMsgWrapper'

export const OChatMessage = ({ msg, scrollChatBottom, disableTyping = false, missionPath }) => {
  const [message, isTyping] = useMessages({
    msg,
    scrollChatBottom,
    customTypingMethods,
  })

  let componentName = msg.type === 'bot-component' ? msg.componentName : msg.type

  if (!!message.data?.type) {
    componentName = message.data.type
  } else if (!!message.data?.name) {
    componentName = message.data.name
  }

  const { ChatComponent } = useChatComponents(componentName)

  const componentOptions = {
    ...(message.componentOptions && message.componentOptions), // data -> data
    ...(message.msg && { msg: message.msg }),
    ...(message.options && { options: message.options }),
    ...(message.onProceed && { onProceed: message.onProceed }),
    ...(message.onQuestion && { onQuestion: message.onQuestion }),
    ...(message.onFinish && { onFinish: message.onFinish }),
    ...(message.componentName && { componentName: message.componentName }),
    ...(message.type !== 'bot-component' && { componentName: message.type }), // data -> name
    ...(!!missionPath && { missionPath }),
    ...(message.data && message.data),
    ...(message.data?.data && !Array.isArray(message.data?.data) && message.data.data),
    ...(message.data?.data && Array.isArray(message.data?.data) && { data: message.data.data }),
    ...(message.data?.name && { componentName: message.data?.name }), // data -> name
    ...(message.data?.type && { componentName: message.data?.type }), // data -> name
  }

  if (componentName === 'user-options' || componentName === 'user-input') {
    return <ChatComponent {...componentOptions} />
  }

  return (
    <MChatMsgWrapper
      first={msg.first}
      strong={msg.strong}
      fullWidth={message?.componentName === 'video'}
      isTyping={!disableTyping && isTyping}
    >
      <ChatComponent {...componentOptions} />
    </MChatMsgWrapper>
  )
}
