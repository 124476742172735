import { useCallback, useEffect, useMemo, useState } from 'react'
import { formatLuxonDate } from '@utils/helpers'
import { nanoid } from 'nanoid/non-secure'
import _ from 'lodash'

import { useTranslations } from '@hooks'

import { DateTime } from 'luxon'

const buildDateRangeString = ({ startsOn, endsOn }) => {
  return `${formatLuxonDate(startsOn)} - ${formatLuxonDate(endsOn)}`
}

const buildMetadata = week => {
  const { startsOn, endsOn, ...rest } = week

  return {
    ...rest,
  }
}

export const useThreadUI = ({ thread, handleNewMessage = () => {} }) => {
  const { t } = useTranslations()

  const [threadId, setThreadId] = useState(null)
  const [messages, setMessages] = useState([])

  const modifiedThread = useMemo(() => {
    if (!thread || !messages) {
      return null
    }

    const filteredMessages = messages.filter(message => !message?.metadata?.hidden)

    return {
      ...thread,
      messages: filteredMessages,
    }
  }, [messages, thread])

  const handleInitMessages = useCallback(() => {
    const { type, metadata } = thread || {}
    const { values } = metadata || {}
    const { lastWeek, previousWeek } = values || {}
    const isInsightsThread = type === 'insights'
    const hasWeekData = lastWeek && previousWeek

    if (!isInsightsThread || !hasWeekData) {
      return setMessages(thread.messages)
    }

    const messageWithWeekDetails = [
      {
        id: nanoid(),
        first: true,
        type: 'bot-component',
        componentName: 'stats',
        componentOptions: {
          title: t('coach.message.weeklyInsights'),
          lastWeekDate: buildDateRangeString(lastWeek),
          previousWeekDate: buildDateRangeString(previousWeek),
          lastWeek: buildMetadata(lastWeek),
          previousWeek: buildMetadata(previousWeek),
        },
      },
    ]

    return setMessages([...messageWithWeekDetails, ...thread.messages])
  }, [t, thread])

  const handleUserInput = useCallback(
    message => {
      handleNewMessage(message)

      setMessages(prev => [
        ...prev,
        {
          id: `temporary-${nanoid()}`,
          type: 'user-options',
          text: message,
          options: [
            {
              label: message,
              isChosen: true,
            },
          ],
          temporary: true,
          createdAt: DateTime.now().toISO(),
        },
      ])
    },
    [handleNewMessage]
  )

  const updateMessages = useCallback(() => {
    const newMessages = _.differenceBy(thread?.messages, messages, 'id')

    if (newMessages.length) {
      setMessages(prev => [...prev, ...newMessages])
    }
  }, [messages, thread?.messages])

  useEffect(() => {
    if (!thread) {
      return setThreadId(null)
    }

    if (!messages.length || thread.id !== threadId) {
      setThreadId(thread.id)
      handleInitMessages()
    } else {
      updateMessages()
    }
  }, [handleInitMessages, messages.length, thread, threadId, updateMessages])

  useEffect(() => {
    if (!threadId && messages.length) {
      setMessages([])
    }
  }, [messages.length, threadId])

  return {
    modifiedThread,
    handleUserInput,
  }
}
