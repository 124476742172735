export const ROUTES = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  SIGN_IN_WITH_EMAIL_AND_PASSWORD: '/sign-in-with-email-and-password',
  ONBOARD: '/onboard',
  TODAY: '/today',
  MISSION: '/topic/:topicId/mission/:missionId',
  // LEADERBOARD: '/leaderboard',
  PROGRAM: '/program',
  PROGRAM_TOPIC: '/program/topic/:topicId',
  PROGRAM_TOPIC_MISSION: '/program/topic/:topicId/mission/:missionId',
  AI: '/ai',
  CATCHUP: '/catchup',
  CHAT: '/chat',
  CHAT_MISSION: '/chat/topic/:topicId/mission/:missionId',
  ACCOUNT: '/account',
  TRIAGE: '/triage/:assistantId',
  BEHAVIOR: '/behavior/:assistantId',
  ENGAGEMENT: '/engagement',
  CHECK_IN: '/check-in/:assistantId',
}

export const ROUTE_NAMES = {
  HOME: 'Home',
  SIGN_IN: 'Sign In',
  ONBOARDING: 'Onboard',
  TODAY: 'Today',
  MISSION: 'Mission',
  // LEADERBOARD: 'Leaderboards',
  PROGRAM: 'Program',
  PROGRAM_TOPIC: 'Progress Topic',
  PROGRAM_TOPIC_MISSION: 'Mission',
  AI: 'AI',
  CATCHUP: 'Catchup',
  CHAT: 'Chat',
  CHAT_MISSION: 'Chat Mission',
  ACCOUNT: 'Account',
  TRIAGE: 'Triage',
  BEHAVIOR: 'Behavior',
  ENGAGEMENT: 'Engagement',
  CHECK_IN: 'Check In',
}

export const generateRoute = (routeName = '', params = {}) => {
  const { missionId, topicId, assistantId } = params

  return routeName
    .replace(':missionId', missionId)
    .replace(':topicId', topicId)
    .replace(':assistantId', assistantId)
}

export const getLocationRouteName = pathname => {
  const matchingRoutesNames = {
    '^/$': ROUTE_NAMES.HOME,
    '^/sign-in$': ROUTE_NAMES.SIGN_IN,
    '^/onboard$': ROUTE_NAMES.ONBOARDING,
    '^/today$': ROUTE_NAMES.TODAY,
    '^/topic/[^/]+/mission/[^/]+$': ROUTE_NAMES.MISSION,
    // '^/leaderboard$': ROUTE_NAMES.LEADERBOARD,
    '^/program$': ROUTE_NAMES.PROGRAM,
    '^/program/topic/[^/]+$': ROUTE_NAMES.PROGRAM_TOPIC,
    '^/program/topic/[^/]+/mission/[^/]+$': ROUTE_NAMES.PROGRAM_TOPIC_MISSION,
    '^/ai$': ROUTE_NAMES.AI,
    '^/catchup$': ROUTE_NAMES.CATCHUP,
    '^/chat$': ROUTE_NAMES.CHAT,
    '^/chat/topic/[^/]+/mission/[^/]+$': ROUTE_NAMES.CHAT_MISSION,
    '^/account$': ROUTE_NAMES.ACCOUNT,
    '^/triage/[^/]+$': ROUTE_NAMES.TRIAGE,
    '^/behavior/[^/]+$': ROUTE_NAMES.BEHAVIOR,
  }

  for (let pattern in matchingRoutesNames) {
    if (new RegExp(pattern).test(pathname)) {
      return matchingRoutesNames[pattern]
    }
  }

  return null
}
