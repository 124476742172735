import { useMemo, useCallback, useEffect, useRef } from 'react'
import { useStateIfMounted } from '../helpers'

import { nanoid } from 'nanoid/non-secure'

export const useThreadEngine = ({ messages, isNewMessagesLoading }) => {
  const loadedMessagesRef = useRef([])
  const [loadedMessages, setLoadedMessages] = useStateIfMounted([])

  const typingIndicatorMeta = useMemo(() => {
    return {
      id: nanoid(),
      type: 'bot-component',
      componentName: 'loading',
      first: true,
    }
  }, [])

  const preloadImage = useCallback(async src => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = () => resolve(img)
      img.onerror = reject
    })
  }, [])

  const handlePreloadImagesInMessages = useCallback(
    async (messages, isMounted) => {
      for (const message of messages) {
        if (isMounted && message?.componentName === 'image') {
          console.log('preloading image...')
          await preloadImage(message?.fileUrl).then(() => {
            console.log('image preloaded')
          })
        }
      }
    },
    [preloadImage]
  )

  const handleTypingIndicator = useCallback(() => {
    const msgsWithoutSkipMsgs = loadedMessagesRef.current.filter(({ skip = false }) => !skip)
    const isLoadingComponentLast =
      msgsWithoutSkipMsgs[msgsWithoutSkipMsgs.length - 1]?.componentName === 'loading'

    const isMsgLoadingAndHasTypingIndicator = isNewMessagesLoading && isLoadingComponentLast
    const isNotLoadingAndHasNotTypingIndicator = !isNewMessagesLoading && !isLoadingComponentLast

    if (isMsgLoadingAndHasTypingIndicator || isNotLoadingAndHasNotTypingIndicator) {
      return
    }

    setLoadedMessages(prev => {
      const modifiedMsgs = [
        ...prev.filter(msg => msg.componentName !== 'loading'),
        ...(isNewMessagesLoading ? [typingIndicatorMeta] : []),
      ]

      loadedMessagesRef.current = modifiedMsgs
      return modifiedMsgs
    })
  }, [isNewMessagesLoading, typingIndicatorMeta, setLoadedMessages])

  const handleNewMessages = useCallback(
    async isMounted => {
      const uniqueMessages = messages.filter(message => {
        return !loadedMessagesRef.current.some(loadedMessage => {
          return loadedMessage.id === message.id
        })
      })
      const currentMessages = loadedMessagesRef.current.filter(
        ({ componentName }) => componentName !== 'loading'
      )

      handlePreloadImagesInMessages(uniqueMessages, isMounted)

      if (currentMessages.length > 0 && uniqueMessages.length === messages.length) {
        return
      }

      if (uniqueMessages.length > 0) {
        return setLoadedMessages(prev => {
          const prevMsgs = [...prev]
          let modifiedUniqueMessages = [...uniqueMessages]
          const lastPrevMessage = prevMsgs[prevMsgs.length - 1]

          if (lastPrevMessage && lastPrevMessage.componentName === 'loading') {
            prevMsgs.pop()
          }

          const lastPrevMsg = [...loadedMessagesRef.current]
            .filter(msg => msg?.componentName !== 'loading')
            .slice(-1)?.[0]
          const firstUniqueMessage = modifiedUniqueMessages[0]

          if (
            !!lastPrevMsg?.options?.[0].label &&
            lastPrevMsg?.options?.[0].label === firstUniqueMessage?.options?.[0].label
          ) {
            console.log('-- skipping duplicate message --')
            modifiedUniqueMessages[0].skip = true
          }

          const newMessagesPayload = [
            ...prevMsgs,
            ...modifiedUniqueMessages,
            ...(isNewMessagesLoading ? [typingIndicatorMeta] : []),
          ]

          loadedMessagesRef.current = newMessagesPayload
          return newMessagesPayload
        })
      }

      handleTypingIndicator()
    },
    [
      handlePreloadImagesInMessages,
      handleTypingIndicator,
      isNewMessagesLoading,
      typingIndicatorMeta,
      messages,
      setLoadedMessages,
    ]
  )

  useEffect(() => {
    let isMounted = true

    handleNewMessages(isMounted)

    return () => (isMounted = false) // cleanup function
  }, [handleNewMessages])

  return loadedMessages
}
