import { useState, useCallback } from 'react'
import classNames from 'classnames'

import { useGoalProvider } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'

export const MChatSelectableOptions = ({ text, options }) => {
  const [selectedOption, setSelectedOption] = useState(null)

  const { onSendHiddenMsg = () => {} } = useGoalProvider()

  const handleChooseOption = useCallback(
    option => {
      onSendHiddenMsg(option.label)
      setSelectedOption(option.id)
    },
    [onSendHiddenMsg]
  )

  return (
    <div className="py-3 px-3.5">
      <p>{text}</p>
      <div className="flex flex-wrap gap-1.5 mt-4">
        {options.map(({ id, label }) => (
          <AButton
            key={id}
            onClick={() => {
              handleChooseOption({ id, label })
            }}
            outline={selectedOption !== id}
            small
            disabled={!!selectedOption}
            className={classNames(selectedOption === id && '!opacity-100')}
          >
            {label}
          </AButton>
        ))}
      </div>
    </div>
  )
}
