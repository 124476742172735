import { useState, useEffect, useCallback } from 'react'
import { useStateIfMounted } from '../helpers'
import { nanoid } from 'nanoid/non-secure'
import { useEffectOnce } from './useEffectOnce'

export const useChatEngine = ({
  initialMessages,
  onUserAction,
  onFinish,
  onExit,
  onProgress,
  onStepUpdated,
  onQuestion,
  continuousWaitFlag = false,
}) => {
  const [isConversationEnd, setIsConversationEnd] = useStateIfMounted(false)
  const [loadedMessages, setLoadedMessages] = useStateIfMounted([])
  const [oaths, setOaths] = useState([])
  const [questions, setQuestions] = useState([])

  const handleOnUserAction = useCallback(
    (loadedMessages, notifyMsg, trigger) => {
      if (onUserAction instanceof Function) {
        onUserAction(loadedMessages, notifyMsg, trigger)
      }
    },
    [onUserAction]
  )

  const handleOnFinish = useCallback(
    (messages, oaths, questions) => {
      if (onFinish instanceof Function) {
        onFinish(messages, oaths, questions)
      }
    },
    [onFinish]
  )

  const handleOnExit = useCallback(() => {
    if (onExit instanceof Function) {
      onExit()
    }
  }, [onExit])

  const handleOnProgress = useCallback(
    loadedMessages => {
      if (onProgress instanceof Function) {
        onProgress(loadedMessages)
      }
    },
    [onProgress]
  )

  const handleOnStepUpdated = useCallback(
    newStep => {
      if (onStepUpdated instanceof Function) {
        onStepUpdated(newStep)
      }
    },
    [onStepUpdated]
  )

  const handleOnQuestion = useCallback(
    questionOrAnswer => {
      if (onQuestion instanceof Function) {
        onQuestion(questionOrAnswer)
      }
    },
    [onQuestion]
  )

  const handleNextStep = newMsg => {
    const newStep = parseInt(newMsg.step)

    handleOnStepUpdated(newStep)
  }

  const handleOathSigned = newOathsSigned => {
    setOaths(prev => [...prev, ...newOathsSigned])
  }

  const removeMsgsCallbacks = msgs => {
    return msgs.map(({ onClick, onProceed, onRender, ...msg }) =>
      msg.options
        ? {
            ...msg,
            options: msg.options.map(({ onClick, ...option }) => option),
          }
        : msg
    )
  }

  const errorMsg = msg => {
    return {
      renderId: nanoid(),
      type: 'bot',
      msg: msg,
      strong: true,
      first: true,
      end: true,
    }
  }

  const getMsgById = id => {
    const msg = initialMessages.find(el => el.id === id)

    return msg || errorMsg(`Error - no msg with this ID ${id}`)
  }

  const getFirstMsg = () => {
    const msg = initialMessages.find(el => el.start === true)

    return msg || errorMsg('Error - no message is first!')
  }

  const handleUserOptions = msg => {
    const feedbackDataMaybe = {
      ...(msg.isFeedback && { isFeedback: msg.isFeedback }),
      ...(msg.feedbackName && { feedbackName: msg.feedbackName }),
    }

    const newUserOptions = msg.options

    newUserOptions?.forEach(option => {
      option.onClick = () => {
        const { onClick, ...notifyOption } = option

        handleOnUserAction(
          removeMsgsCallbacks([...loadedMessages, msg]),
          { ...notifyOption, ...feedbackDataMaybe },
          option.trigger
        )

        loadNextMsg(option.trigger, true)
      }
    })

    return { options: newUserOptions }
  }

  const handleWaitFlag = msg => {
    return {
      onProceed: trigger => {
        const { onProceed, ...notifyMsg } = msg

        handleOnUserAction(
          removeMsgsCallbacks([...loadedMessages, msg]),
          notifyMsg,
          trigger || msg.trigger
        )

        loadNextMsg(trigger || msg.trigger)
      },
    }
  }

  const handleMsgCallback = msg => {
    if (msg.type === 'user-options') return handleUserOptions(msg)

    if (msg.wait) return handleWaitFlag(msg)

    if (!msg.end && continuousWaitFlag) {
      return { onRender: trigger => loadNextMsg(trigger || msg.trigger) }
    }
  }

  const loadNextMsg = useCallback((msgId, shouldMarkMsgAsFirst = false) => {
    const assessmentComponentNames = ['question', 'answer']
    const initialNextMsg = msgId ? getMsgById(msgId) : getFirstMsg()
    const nextMsg = {
      ...initialNextMsg,
      ...handleMsgCallback(initialNextMsg),
      renderId: nanoid(),
      first: initialNextMsg.type !== 'user-options' && shouldMarkMsgAsFirst,
    }

    if (
      nextMsg.type === 'meta-component' &&
      assessmentComponentNames.includes(nextMsg.componentName)
    ) {
      handleOnQuestion(nextMsg.componentOptions)
      setQuestions(prev => [...prev, nextMsg.componentOptions])

      return loadNextMsg(nextMsg.trigger)
    }

    if (nextMsg.step) handleNextStep(nextMsg)

    if (nextMsg.oath && nextMsg.componentOptions.oaths) {
      handleOathSigned(nextMsg.componentOptions.oaths)
    }

    setLoadedMessages(prev => [
      ...prev,
      {
        ...nextMsg,
        ...(nextMsg.doesHandleQuestionnaire && {
          onQuestion: questionOrAnswer => {
            handleOnQuestion(questionOrAnswer)
            setQuestions(prev => [...prev, questionOrAnswer])
          },
        }),
      },
    ])

    if (nextMsg.end) {
      setIsConversationEnd(true)
    } else if (!nextMsg.onRender && !nextMsg.wait && nextMsg.type !== 'user-options') {
      setTimeout(() => {
        loadNextMsg(nextMsg.trigger)
      }, 0)
    }
  }, [])

  useEffectOnce(() => loadNextMsg(false, true))

  useEffect(() => {
    if (loadedMessages.length) handleOnProgress(removeMsgsCallbacks(loadedMessages))
  }, [loadedMessages, handleOnProgress])

  useEffect(() => {
    if (!isConversationEnd) {
      return
    }

    const dontSetAsFinished = loadedMessages.find(msg => msg.end)?.dontSetAsFinished || false

    if (dontSetAsFinished) {
      handleOnExit()
    } else {
      handleOnFinish(removeMsgsCallbacks(loadedMessages), oaths, questions)
    }
  }, [isConversationEnd, loadedMessages, oaths, questions, handleOnExit, handleOnFinish])

  return loadedMessages
}
