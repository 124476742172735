import { useCallback, useState } from 'react'

import { File } from 'react-feather'
import { ACloseIcon } from '../atoms/ACloseIcon'
import { MChatModal } from './MChatModal'
import { AMarkdown } from '../atoms/AMarkdown'

export const MChatAudioTranscript = ({ transcriptMarkdown }) => {
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false)

  const openTranscript = useCallback(() => {
    setIsTranscriptModalOpen(true)
  }, [])

  const closeTranscript = useCallback(() => {
    setIsTranscriptModalOpen(false)
  }, [])

  return (
    <div>
      <button
        onClick={openTranscript}
        className="flex items-center pr-0.5 rounded focus:ring-offset-2 focus:outline-none focus:ring-1 focus:ring-green-dark cursor-pointer"
      >
        <File size={24} color="#084E33" />
        <div className="ml-1 text-xs leading-[14px] text-green-dark text-left font-medium">
          View
          <br />
          Transcript
        </div>
      </button>
      <MChatModal isOpen={isTranscriptModalOpen} onClose={closeTranscript}>
        <div className="max-w-lg pt-10 px-6 pb-6 bg-wildSand rounded-lg overflow-hidden">
          <div className="absolute top-2 right-2 flex">
            <ACloseIcon iconName="x" onClick={closeTranscript} />
          </div>
          <div className="max-h-[300px] pr-3 overflow-y-auto">
            <AMarkdown>{transcriptMarkdown}</AMarkdown>
          </div>
        </div>
      </MChatModal>
    </div>
  )
}
