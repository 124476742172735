import React, { useMemo } from 'react'
import _ from 'lodash'
import { useUser } from '@edwin/react-sdk'

import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatBotMsg = ({ msg }) => {
  const { user } = useUser()

  const parsedMsg = useMemo(() => {
    try {
      const parsed = _.template(msg)({ user })

      return parsed
    } catch (e) {
      console.warn(e)

      return msg
    }
  }, [msg, user])

  return (
    <div className="py-3 px-3.5">
      <AMarkdown>{parsedMsg}</AMarkdown>
    </div>
  )
}
