import React from 'react'
import './index.scss'

import {
  AuthorProvider,
  UserProvider,
  GoalProvider,
  ChatComponentProvider,
  SummaryProvider,
  InteractiveProvider,
} from '@edwin/react-sdk'
import { configChatComponents } from './utils/configChatComponents'

import { OMissionDetails } from './components/organisms/OMissionDetails'

export const Mission = ({
  mission = {},
  thread,
  handleNewMessage = () => {},
  getGoal = () => {},
  getMission = () => {},
  getMissionProgress = () => {},
  updateGoal = () => {},
  onMissionClick = () => {},
  onAssistantAction = () => {},
  onSendHiddenMsg = () => {},
  user = {
    firstName: '',
    lastName: '',
  },
  chatComponents = {},
  interactiveComponents = {},
  onUserAction,
  onClose,
  onFinish,
  onExit,
  onProgress,
  onGoal,
  doesGoalExist,
  onStepUpdated,
  onQuestion,
  points,
  missionTitle,
  translations,
  shouldScrollItself = false,
  shouldDisplayCover = true,
  shouldDisplaySidebar = true,
  shouldDisplayCloseIcon = true,
  ...rest
}) => {
  const { author = null, pointsForCompletion, isCurrent = false } = mission

  return (
    <AuthorProvider author={author}>
      <UserProvider user={user}>
        <GoalProvider
          onGoal={onGoal}
          doesGoalExist={doesGoalExist}
          sendMessage={handleNewMessage}
          getGoal={getGoal}
          getMission={getMission}
          getMissionProgress={getMissionProgress}
          updateGoal={updateGoal}
          onMissionClick={onMissionClick}
          onAssistantAction={onAssistantAction}
          onSendHiddenMsg={onSendHiddenMsg}
        >
          <ChatComponentProvider
            customChatComponents={chatComponents}
            defaultChatComponents={configChatComponents}
          >
            <InteractiveProvider customInteractiveComponents={interactiveComponents}>
              <SummaryProvider
                missionTitle={missionTitle}
                points={points}
                pointsForCompletion={pointsForCompletion}
                isCurrent={isCurrent}
                translations={translations}
              >
                <div className="react-web--edwinsandbox__container" style={{ height: '100%' }}>
                  <OMissionDetails
                    mission={mission}
                    thread={thread}
                    onUserAction={onUserAction}
                    onClose={onClose}
                    onFinish={onFinish}
                    onExit={onExit}
                    onProgress={onProgress}
                    onStepUpdated={onStepUpdated}
                    onQuestion={onQuestion}
                    translations={translations}
                    shouldScrollItself={shouldScrollItself}
                    shouldDisplayCover={shouldDisplayCover}
                    shouldDisplaySidebar={shouldDisplaySidebar}
                    shouldDisplayCloseIcon={shouldDisplayCloseIcon}
                    {...rest}
                  />
                </div>
              </SummaryProvider>
            </InteractiveProvider>
          </ChatComponentProvider>
        </GoalProvider>
      </UserProvider>
    </AuthorProvider>
  )
}
