import { useState } from 'react'

import { DateTime } from 'luxon'
import { AButton } from '../atoms/AButton'
import { useGoalProvider } from '@edwin/react-sdk'

export const MChatCreateGoal = ({
  title,
  prompt,
  description,
  type = 'boolean',
  typeMeta = { trueLabel: '', falseLabel: '' },
  timeSettings = {
    startOffset: 0,
    instanceDuration: 1,
    daysBetweenInstances: 1,
    totalInstances: 1,
  },
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false)

  const { onGoal = () => {}, doesGoalExist } = useGoalProvider()

  return (
    <div className="py-3 px-1.5">
      <span className="py-1 px-2 text-xs text-codGray font-semibold bg-green-light rounded">
        Create goal
      </span>
      <div className="mt-1 px-2">
        <>
          <h4 className="mt-2 text-[18px] leading-[24px]">{title}</h4>
          <div className="w-12 h-1 mt-1.5 mb-2.5 bg-codGray" />
        </>
        {description && <p className="mb-1 text-sm">{description}</p>}
        {!isConfirmed && (
          <div className="max-w-72">
            <span className="px-1 text-xs font-medium text-gray-700">Start offset</span>
            <input
              type="number"
              className="w-full h-8 px-2 text-gray-600 border border-gray-400 rounded"
              defaultValue={timeSettings?.startOffset}
              disabled={isConfirmed}
              onChange={e => {
                // setEditingData({ ...editingData, prompt: e.target.value })
              }}
            />
            <span className="px-1 text-xs font-medium text-gray-700">Instance Duration</span>
            <input
              type="number"
              className="w-full h-8 px-2 text-gray-600 border border-gray-400 rounded"
              defaultValue={timeSettings?.instanceDuration}
              disabled={isConfirmed}
              onChange={e => {
                // setEditingData({ ...editingData, prompt: e.target.value })
              }}
            />
            <span className="px-1 text-xs font-medium text-gray-700">Days Between Instances</span>
            <input
              type="number"
              className="w-full h-8 px-2 text-gray-600 border border-gray-400 rounded"
              defaultValue={timeSettings?.daysBetweenInstances}
              disabled={isConfirmed}
              onChange={e => {
                // setEditingData({ ...editingData, prompt: e.target.value })
              }}
            />
            <span className="px-1 text-xs font-medium text-gray-700">Total Instances</span>
            <input
              type="number"
              className="w-full h-8 px-2 text-gray-600 border border-gray-400 rounded"
              defaultValue={timeSettings?.totalInstances}
              disabled={isConfirmed}
              onChange={e => {
                // setEditingData({ ...editingData, prompt: e.target.value })
              }}
            />
          </div>
        )}

        <div className="flex mt-4 gap-x-1.5">
          {isConfirmed ? (
            <div className="font-medium">Goal is created</div>
          ) : (
            <AButton
              small
              dontRound
              onClick={() => {
                setIsConfirmed(true)
              }}
            >
              Confirm
            </AButton>
          )}
        </div>
      </div>
    </div>
  )
}
