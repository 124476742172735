import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import ReactMarkdown from 'react-markdown'
import { AButton } from '../atoms/AButton'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatInteractiveChecklist = ({
  title,
  choices,
  textBeforeOaths,
  multipleSelection = false,
  triggers,
  onProceed = () => {},
}) => {
  const { user } = useUser()
  const [checkedChoices, setCheckedChoices] = useState(choices.map(() => false))
  const [isFinished, setIsFinished] = useState(false)
  const [isAtLeastOneSelected, setIsAtLeastOneSelected] = useState(false)

  useEffect(() => {
    setIsAtLeastOneSelected(Object.values(checkedChoices).some(value => !!value))
  }, [checkedChoices])

  const toggleChoiceAtIndex = index => {
    const newcheckedChoices = checkedChoices.map(value => (multipleSelection ? value : false))
    newcheckedChoices[index] = !newcheckedChoices[index]

    setCheckedChoices(newcheckedChoices)
  }

  const destinationTrigger = () => {
    const selectedFootprint = Object.entries(checkedChoices)
      .filter(([, value]) => value)
      .map(([id]) => id)
      .sort()
      .join(',')

    return triggers[selectedFootprint] || triggers.else || triggers
  }

  const handleOnProceed = () => {
    if (isAtLeastOneSelected) {
      onProceed(destinationTrigger())
      setIsFinished(true)
    } else {
      alert('Select something first')
    }
  }

  return (
    <div className="flex flex-col py-3 px-3.5 lg:px-5">
      {title && <h4 className="text-lg mb-3">{title}</h4>}
      {textBeforeOaths && <p className="mb-3">{textBeforeOaths}</p>}
      <ul>
        {choices.map((oath, index) => (
          <li key={index} className="flex items-start mb-2.5">
            <div className="flex h-5 items-center">
              <input
                id={oath}
                name={oath}
                type="checkbox"
                disabled={isFinished}
                checked={checkedChoices[index]}
                onChange={() => {
                  toggleChoiceAtIndex(index)
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    toggleChoiceAtIndex(index)
                  }
                }}
                className={classnames(
                  'h-5 w-5 border-1 border-alto text-green-dark focus:ring-1 focus:ring-offset-0 focus:ring-green-dark cursor-pointer checked:border-green-dark disabled:cursor-auto',
                  multipleSelection && 'rounded-full'
                )}
              />
            </div>
            <label htmlFor={oath} className="ml-3">
              <ReactMarkdown className={classnames(!isFinished ? 'cursor-pointer' : '')}>
                {_.template(oath)({ user })}
              </ReactMarkdown>
            </label>
          </li>
        ))}
      </ul>
      <div className="mt-2">
        {!isFinished && (
          <AButton disabled={!isAtLeastOneSelected} onClick={handleOnProceed} small dontRound>
            {multipleSelection ? 'Submit answers' : 'Submit answer'}
          </AButton>
        )}
      </div>
    </div>
  )
}

MChatInteractiveChecklist.propTypes = {
  title: PropTypes.string,
  textBeforeOaths: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  onProceed: PropTypes.func.isRequired,
  multipleSelection: PropTypes.bool,
}
