import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useUser } from '@edwin/react-sdk'

import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatFormatted = ({ title, descriptionMarkdown, onRender = () => {} }) => {
  const { user } = useUser()

  return (
    <div className="py-3 px-3.5">
      {title && (
        <AMarkdown paragraphStyles="text-[22px] leading-[30px]" className="mt-1 mb-2.5 font-p22">
          {title}
        </AMarkdown>
      )}
      <AMarkdown>{_.template(descriptionMarkdown)({ user })}</AMarkdown>
    </div>
  )
}

MChatFormatted.propTypes = {
  title: PropTypes.string,
  descriptionMarkdown: PropTypes.string,
  infoSourceButtonLabel: PropTypes.string,
  infoSource: PropTypes.shape({
    title: PropTypes.string,
    headerTitle: PropTypes.string,
    descriptionMarkdown: PropTypes.string,
    externalLink: PropTypes.string,
    externalLinkLabel: PropTypes.string,
  }),
}
