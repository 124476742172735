import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useStore from '@store/useStore'
import { useNavigate, useParams } from 'react-router-dom'
import { generateRoute, ROUTES } from '@const/Routes'

import { useThreadUI } from '@hooks/useThreadUI'
import {
  createAssistantThreadWithInput,
  markAssistantComponentThreadAsCompleted,
} from '@services/Insights'
import { sendThreadMessage, useThread } from '@services/Assistant'

import { Mission } from '@edwinsandbox/react-web'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { MChatInput } from '@molecules/MChatInput'

export const PCheckIn = () => {
  const navigate = useNavigate()
  const { assistantId = '' } = useParams()

  const [thread] = useThread(assistantId)

  const [isNewMessagesLoading, setIsNewMessagesLoading] = useState(false)

  const user = useStore(state => state.user)

  const isLastMsgSelectableOptions = useMemo(() => {
    const lastMsg = thread?.messages?.[thread?.messages?.length - 1]

    return lastMsg?.data?.type === 'display-selectable-options'
  }, [thread?.messages])

  const sendMessage = useCallback(
    async (message, hidden) => {
      setIsNewMessagesLoading(true)

      await sendThreadMessage({ threadId: assistantId, message, hidden })
    },
    [assistantId]
  )

  const { modifiedThread, handleUserInput } = useThreadUI({
    thread,
    handleNewMessage: sendMessage,
  })

  const handleAssistantFlowAction = useCallback(
    async ({ type, input }) => {
      try {
        let navigateTo = ROUTES.ROOT

        const shouldContinueFlow = !!type && type !== 'end'

        if (shouldContinueFlow) {
          const { id, type: assistantType } = await createAssistantThreadWithInput({
            type,
            input: thread?.objectives,
          })

          const assistantRoutes = {
            check_in: ROUTES.CHECK_IN,
            choose_behavior: ROUTES.BEHAVIOR,
            triage: ROUTES.TRIAGE,
          }

          navigateTo = generateRoute(assistantRoutes[assistantType], { assistantId: id })
        }

        if (thread?.id) {
          await markAssistantComponentThreadAsCompleted({ threadId: thread.id })
        }

        navigate(navigateTo)
      } catch (error) {
        console.error(error)
      }
    },
    [navigate, thread?.id, thread?.objectives]
  )

  useEffect(() => {
    const isRemoteThreadLoading = thread?.inProgress

    setIsNewMessagesLoading(isRemoteThreadLoading)
  }, [thread])

  return (
    <OPageWrapper
      className="flex flex-col h-full"
      contentClassName="flex flex-col gap-y-3 divide-y-[1px] divide-gray-300"
    >
      <div className="h-1/5 pb-4">
        <div className="text-sm">
          <span className="font-bold">Check in</span>
        </div>
        <div className="flex-1 flex flex-wrap justify-start items-center gap-x-2.5 py-2 h-full">
          {thread?.objectives?.map(({ id, label }) => (
            <div key={id} className="flex text-sm">
              <CheckCircleIcon className="w-3.5" />
              <span className="ml-0.5 whitespace-nowrap">{label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-1 flex flex-col h-4/5 pt-4">
        <div className="h-full pb-2 overflow-hidden">
          <Mission
            user={user}
            thread={modifiedThread}
            onAssistantAction={handleAssistantFlowAction}
            onSendHiddenMsg={msg => {
              sendMessage(msg, true)
            }}
            shouldDisplayCover={false}
            shouldDisplaySidebar={false}
            shouldScrollItself
            isNewMessagesLoading={isNewMessagesLoading}
          />
        </div>
        <MChatInput
          handleOnSubmit={handleUserInput}
          isInputDisabled={isNewMessagesLoading || isLastMsgSelectableOptions}
          isNewMessagesLoading={isNewMessagesLoading}
          className="px-2.5 sm:px-0"
        />
      </div>
    </OPageWrapper>
  )
}
