import React, { useCallback, useRef } from 'react'
import classNames from 'classnames'
import { useChatAnimation } from '@edwin/react-sdk'
import { useMessages } from '../../utils/useMessages'

import ReactCanvasConfetti from 'react-canvas-confetti'
import { OChatMessage } from './OChatMessage'

export const OChat = ({
  initialMessages,
  completionMeta,
  onUserAction,
  onFinish,
  onExit,
  onProgress,
  onStepUpdated,
  onQuestion,
  shouldScrollItself = false,
  shouldDisplaySidebar = true,
}) => {
  const refWrapper = useRef()
  const refAnimationInstance = useRef(null)

  const getInstance = useCallback(instance => {
    refAnimationInstance.current = instance
  }, [])

  const makeShot = useCallback((particleRatio, opts) => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.98, x: 0.5 },
        particleCount: Math.floor(200 * particleRatio),
      })
    }
  }, [])

  const triggerConfetti = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    })

    makeShot(0.2, {
      spread: 60,
    })

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    })
  }, [makeShot])

  // const INTERACTIVE_MESSAGES = [
  //   {
  //     id: 'start',
  //     type: 'bot',
  //     msg: 'Hi there! Welcome to the interactive chat! 🎉',
  //     start: true,
  //     trigger: 'usr-opt',
  //   },
  //   {
  //     id: 'usr-opt',
  //     type: 'user-options',
  //     options: [
  //       {
  //         id: '1',
  //         label: "Let's start! 🚀",
  //         trigger: 'chat',
  //         newDifferentiator: 'chat',
  //       },
  //     ],
  //   },
  //   {
  //     id: 'chat',
  //     type: 'bot-component',
  //     componentName: 'interactive-chat',
  //     componentOptions: {
  //       title: 'How are you feeling today? 😊',
  //       description: 'Let me know by quick conversation!',
  //       buttons: {
  //         open: 'Let’s talk',
  //         close: 'Not now',
  //       },
  //       // additionalAIInstructions: ''
  //     },
  //     wait: true,
  //     trigger: 'end',
  //   },
  //   // {
  //   //   id: 1,
  //   //   type: 'bot-component',
  //   //   componentName: 'interactive-mood',
  //   //   componentOptions: {
  //   //     mood: 'happy',
  //   //   },
  //   //   wait: true,
  //   //   trigger: 2,
  //   // },
  //   // {
  //   //   id: 2,
  //   //   type: 'bot-component',
  //   //   componentName: 'interactive-tracker',
  //   //   componentOptions: {
  //   //     mood: 'happy',
  //   //   },
  //   //   wait: true,
  //   //   trigger: 3,
  //   // },
  //   // {
  //   //   id: 3,
  //   //   type: 'bot-component',
  //   //   componentName: 'interactive-conversation',
  //   //   componentOptions: {
  //   //     mood: 'happy',
  //   //   },
  //   //   wait: true,
  //   //   trigger: 4,
  //   // },
  //   // {
  //   //   id: 4,
  //   //   type: 'bot-component',
  //   //   componentName: 'interactive-sleep',
  //   //   componentOptions: {
  //   //     mood: 'happy',
  //   //   },
  //   //   wait: true,
  //   //   trigger: 5,
  //   // },
  //   // {
  //   //   id: 5,
  //   //   type: 'bot-component',
  //   //   componentName: 'interactive-progress',
  //   //   componentOptions: {
  //   //     mood: 'happy',
  //   //   },
  //   //   wait: true,
  //   //   trigger: 'end',
  //   // },
  //   {
  //     id: 'end',
  //     type: 'user-options',
  //     msg: 'That was fun! 🎉',
  //     end: true,
  //   },
  // ]

  const messages = useMessages({
    initialMessages,
    completionMeta,
    onUserAction,
    onFinish,
    onExit,
    onProgress,
    onStepUpdated,
    onQuestion,
    triggerConfetti,
    continuousWaitFlag: true,
  })

  const scrollChatBottom = useChatAnimation(messages, refWrapper, shouldScrollItself)

  return (
    <>
      <div
        ref={refWrapper}
        className={classNames('px-2.5', shouldScrollItself && 'h-full overflow-y-auto')}
      >
        {messages.map(msg => (
          <div
            key={msg.renderId}
            className={classNames(
              'animate-message opacity-0',
              shouldDisplaySidebar && 'first:pt-20 lg:first:pt-6'
            )}
          >
            <OChatMessage msg={msg} scrollChatBottom={scrollChatBottom} missionPath={messages} />
          </div>
        ))}
      </div>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          pointerEvents: 'none',
          width: '100%',
          height: '100%',
        }}
      />
    </>
  )
}
