import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES, generateRoute } from '@const/Routes'
import { useActiveTopics, useUpdateLeaderboard, useTranslations, useProgramTimeline } from '@hooks'
import { checkIfMobile } from '@utils/helpers'
import classnames from 'classnames'

import useStore, { useEphemeralStore } from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
// import usePointsStore from '@store/usePointsStore'
import useGoalsStore from '@store/useGoalsStore'
import useTipsStore from '@store/useTipsStore'

import { useMostRecentInsight } from '@services/Insights'

import { AHeading } from '@atoms/AHeading'
import { MSlider } from '@molecules/MSlider'
import { MPointsStats } from '@molecules/MPointsStats'
import { MLeaderboard } from '@molecules/MLeaderboard'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { OMissionCover } from '@organisms/OMissionCover'
import { OTopicModal } from '@organisms/OTopicModal'
import { OTodaySkeleton } from '@organisms/OTodaySkeleton'
import { OTodayTips } from '@organisms/OTodayTips'
import { OTracker } from '@organisms/OTracker'
import { OInsights } from '@organisms/OInsights'

export const PToday = ({ isLoading }) => {
  const { t } = useTranslations()
  // const [isMobile, setIsMobile] = useState(checkIfMobile())

  const navigate = useNavigate()

  const searchGoalInstances = useGoalsStore(state => state.searchGoalInstances)
  const goalInstances = useGoalsStore(state => state.goalInstances)
  const dateNow = useEphemeralStore(state => state.dateNow)

  const getMission = useProgressStore(state => state.getMission)

  const tips = useTipsStore(state => state.tips)
  const searchTips = useTipsStore(state => state.searchTips)

  const [mostRecentInsight, isMostRecentInsightLoading, isMostRecentInsightError] =
    useMostRecentInsight()

  const [insightId, setInsightId] = useState(null)

  // useUpdateLeaderboard()

  // const { uid } = useStore(state => ({
  //   uid: state.user?.id,
  // }))
  // const team = useStore(state => state.team)

  // const userProgress = useProgressStore(state => state.userProgress)
  // const getTopicsLength = useProgressStore(state => state.getTopicsLength)
  // const getCompletedAssignmentsNumber = useProgressStore(
  //   state => state.getCompletedAssignmentsNumber
  // )

  const activeTopics = useActiveTopics()
  // const { currentProgramDay, activeTopics } = useProgramTimeline()

  // const teamLeaderboard = usePointsStore(state => state.teamLeaderboard)
  // const getUserPoints = usePointsStore(state => state.getUserPoints)

  // const topicsLength = useMemo(() => {
  //   const progressTopicsNumber = getTopicsLength()
  //   const notStartedTopicsNumber = activeTopics?.reduce((acc, currVal) => {
  //     const topicIsStarted = !!currVal.missionsAndSeries.find(
  //       ({ isCompleted = false }) => isCompleted
  //     )
  //
  //     return topicIsStarted ? acc : acc + 1
  //   }, 0)
  //
  //   return progressTopicsNumber + notStartedTopicsNumber
  // }, [activeTopics, getTopicsLength])

  // const completedAssignmentsNumber = useMemo(() => {
  //   return getCompletedAssignmentsNumber(userProgress)
  // }, [getCompletedAssignmentsNumber, userProgress])

  // const userPoints = useMemo(() => {
  //   return getUserPoints(teamLeaderboard)
  // }, [getUserPoints, teamLeaderboard])

  const instances = useMemo(() => {
    if (!dateNow) return []

    const fromDateTime = dateNow.startOf('day').plus({ seconds: 1 })
    const toDateTime = dateNow.endOf('day')

    const todayInstances = searchGoalInstances(fromDateTime, toDateTime, goalInstances)

    return todayInstances
  }, [searchGoalInstances, goalInstances, dateNow])

  const availableTips = useMemo(() => {
    const availableTips = searchTips(dateNow, tips)

    return availableTips?.map(tip => {
      const mission = getMission(tip.missionId)

      return {
        ...tip,
        isMissionAvailable: !!mission,
      }
    })
  }, [dateNow, getMission, searchTips, tips])

  // const handleClickLeaderboard = useCallback(() => {
  //   navigate(ROUTES.LEADERBOARD)
  // }, [navigate])

  const handleClickMission = useCallback(
    (topicId, missionId) => {
      navigate(generateRoute(ROUTES.MISSION, { topicId, missionId }))
    },
    [navigate]
  )

  useEffect(() => {
    if (!isMostRecentInsightLoading && mostRecentInsight?.id) {
      setInsightId(mostRecentInsight.id)
    }
  }, [isMostRecentInsightLoading, mostRecentInsight?.id])

  // const handleResize = useCallback(() => {
  //   setIsMobile(checkIfMobile())
  // }, [])

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize)
  //
  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [handleResize])

  if (isLoading) {
    return (
      <OPageWrapper>
        <OTodaySkeleton />
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper>
      {instances.length ? (
        <div>
          <AHeading>{t('headings.goals')}</AHeading>
          <div className="mt-4 flex flex-wrap gap-y-4 gap-x-3.5 mb-6 lg:mb-8">
            {instances.map(instance => (
              <OTracker key={instance.id} {...instance} />
            ))}
          </div>
        </div>
      ) : null}

      {activeTopics?.map(({ id, name, missionsAndSeries }) => {
        const activeItem = missionsAndSeries.findIndex(({ isCurrent }) => isCurrent) || 0

        return (
          <div key={id}>
            {/*<AHeading>{t('topicHeading', { topicName: name })}</AHeading>*/}
            <AHeading>{name}</AHeading>
            <MSlider
              startIndex={activeItem}
              className="mt-4 -mx-3 sm:-mx-5 lg:mx-0 overflow-x-hidden"
              sliderClassName="items-start pr-3 lg:pr-1"
              leftPaddingPlaceholder={<div className="ml-3 sm:ml-5 lg:ml-0 -mr-1.5" />}
            >
              {missionsAndSeries.map((missionOrSeries, index) => (
                <OMissionCover
                  key={index}
                  mission={missionOrSeries}
                  isActive={activeItem === index}
                  className={classnames('snap-center flex-shrink-0 mx-1.5 last:mr-0')}
                  onClick={() => {
                    handleClickMission(id, missionOrSeries.id)
                  }}
                />
              ))}
            </MSlider>
          </div>
        )
      })}

      {availableTips?.length ? (
        <div className="mt-2 lg:mt-4">
          <AHeading>{t('headings.tips')}</AHeading>
          <OTodayTips availableTips={availableTips} />
        </div>
      ) : null}

      <OTopicModal />

      <OInsights threadId={insightId} onCloseBanner={() => setInsightId(null)} />
    </OPageWrapper>
  )
}
